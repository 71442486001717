<template>
	<div
		class="block-blog-header"
		data-qa="blog-postinformation"
	>
		<div class="block-blog-header__content">
			<h1
				class="font-primary block-blog-header__title block-blog-header__text-block"
				data-qa="blog-label-posttitle"
			>
				{{ currentPage.meta.title }}
			</h1>
			<p
				v-if="data.settings.shownItems.description"
				class="font-secondary block-blog-header__text-block"
				data-qa="blog-label-postdescription"
			>
				{{ currentPage.meta.description }}
			</p>
			<BlockBlogListItemCategories
				v-if="data.settings.shownItems.categories && currentPage.customData.categories.length"
				class="font-secondary"
				data-qa="blog-label-category"
				:categories="currentPage.customData.categories"
			/>
			<BlockBlogListItemMeta
				class="block-blog-header__meta"
				v-bind="{
					authorName: userFullName,
					minutesAmount: currentPage.customData.minutesToRead,
					date: currentPage.customData.date,
					showAvatar: data.settings.shownItems.avatar,
					showName: data.settings.shownItems.authorFullName,
					showDate: data.settings.shownItems.date,
					showMinutes: data.settings.shownItems.minutesToRead
				}"
			/>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
} from 'vuex';

import BlockBlogListItemCategories from '@/components/block-blog/block-blog-list-item/BlockBlogListItemCategories.vue';
import BlockBlogListItemMeta from '@/components/block-blog/block-blog-list-item/BlockBlogListItemMeta.vue';

export default {
	components: {
		BlockBlogListItemMeta,
		BlockBlogListItemCategories,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapState('user', ['user']),
		...mapGetters('pages', ['currentPage']),
		userFullName() {
			return this.user?.fullName || this.$t('common.name');
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@user/components/block-blog/block-blog-header/BlockBlogHeader";
</style>
